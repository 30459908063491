<template>
  <div>
    <loading-spinner v-if="loading" :loading="loading" />
    <div class="p-2">
      <v-date-picker
        mode="range"
        is-required
        locale="nl"
        :step="1"
        :input-props="{
          class: 'appearance-none border py-2',
          readonly: true
        }"
        :attributes="[
          {
            key: 'today',
            dot: true,
            dates: new Date()
          }
        ]"
        :rows="1"
        :columns="2"
        :value="{ start: startDate, end: endDate }"
        :update-on-input="false"
        :popover="{ placement: 'bottom', visibility: 'click' }"
        @input="onDateRangeUpdated($event)"
      >
        <b-button-group class="float-right w-min-vc">
          <b-input-group-prepend>
            <b-button variant="light" title="Selecteer periode">
              <i class="far fa-calendar-alt"></i>
            </b-button>
            <b-button variant="light" @click.stop="onPrev">
              <i class="fa fa-chevron-left"></i>
            </b-button>
          </b-input-group-prepend>
          <b-input-group-text slot="append" class="date-range w-100"> {{ startDate | moment("ll") }} - {{ endDate | moment("ll") }} </b-input-group-text>
          <b-input-group-append>
            <b-button variant="light" @click.stop="onNext">
              <i class="fa fa-chevron-right"></i>
            </b-button>
            <b-button variant="light" title="Komende periode" @click.stop="resetViewPeriod">
              <i class="far fa-calendar-check"></i>
            </b-button>
          </b-input-group-append>
        </b-button-group>
      </v-date-picker>

      <h1>Kampeerplaatsen</h1>
    </div>
    <div class="flex-grow">
      <full-calendar ref="fullCalendar" class="booking-calendar fc fc-media-screen fc-direction-ltr fc-theme-standard" :class="{ scrolled: scrolled }" :options="calendarOptions" />
    </div>
    <b-modal
      id="booking-change-info"
      :title="$t('booking.date_changes.title')"
      cancel-title="Ongedaan maken"
      cancel-variant="light"
      ok-variant="success"
      ok-title="Wijziging opslaan"
      :no-close-on-esc="true"
      :hide-header-close="true"
      :ok-disabled="bookingChanges.oldEvent && !pitchStartEditable(bookingChanges)"
      @cancel="eventChangeCancel"
      @ok="eventChangeConfirm"
    >
      <div v-if="bookingChanges.oldEvent">
        <b-alert :show="!pitchStartEditable(bookingChanges)" variant="danger"> De startdatum van de boeking kan niet meer aangepast worden! </b-alert>
        <p v-if="bookingChanges.bookingDelta !== 0">
          {{
            $tc("booking.date_changes.dragged_dates", bookingChanges.bookingDelta, {
              days: Math.abs(bookingChanges.bookingDelta),
              direction: deltaDirectionString(bookingChanges.bookingDelta),
              bookingNumber: bookingChanges.newEvent.extendedProps.booking.bookingNumber
            })
          }}
        </p>
        <p v-if="bookingChanges.startDelta !== 0">
          {{
            $tc("booking.date_changes.start_date", bookingChanges.startDelta, {
              days: Math.abs(bookingChanges.startDelta),
              direction: deltaDirectionString(bookingChanges.startDelta),
              bookingNumber: bookingChanges.newEvent.extendedProps.booking.bookingNumber
            })
          }}
        </p>
        <p v-if="bookingChanges.endDelta !== 0">
          {{
            $tc("booking.date_changes.end_date", bookingChanges.endDelta, {
              days: Math.abs(bookingChanges.endDelta),
              direction: deltaDirectionString(bookingChanges.endDelta),
              bookingNumber: bookingChanges.newEvent.extendedProps.booking.bookingNumber
            })
          }}
        </p>
        <p v-if="bookingChanges.bookingDelta !== 0 || bookingChanges.startDelta !== 0 || bookingChanges.endDelta !== 0">
          {{
            $t("booking.date_changes.new_dates", {
              startDate: moment(bookingChanges.newEvent.start).format("D MMMM"),
              endDate: moment(bookingChanges.newEvent.end).format("D MMMM"),
              bookingNumber: bookingChanges.newEvent.extendedProps.booking.bookingNumber
            })
          }}
        </p>
        <p v-if="bookingChanges.newResource">
          {{
            $t("booking.date_changes.new_pitch", {
              pitchName: bookingChanges.newResource.title
            })
          }}
        </p>
      </div>
    </b-modal>
    <b-modal
      v-if="booking"
      id="booking-info"
      :title="$tc('booking.booking', 1) + ' ' + booking.bookingNumber"
      :ok-only="true"
      ok-variant="success"
      ok-title="Open boeking"
      @ok="$router.push({ name: 'booking-detail', params: { id: booking.id } })"
    >
      <div class="row">
        <div class="col">{{ $t("booking.status") }}</div>
        <div class="col">
          <booking-status :booking="booking" />
        </div>
      </div>
      <div class="row">
        <div class="col">{{ $t("booking.name") }}</div>
        <div class="col">{{ booking.name }}</div>
      </div>
      <div class="row">
        <div class="col">{{ $t("booking.dates") }}</div>
        <div class="col">
          {{ booking.startDate | moment("D MMMM") }} -
          {{ booking.endDate | moment("D MMMM") }}
        </div>
      </div>
      <div class="row">
        <div class="col">{{ $t("booking.pitch") }}</div>
        <div v-if="booking.pitches && booking.pitches.length" class="col">{{ booking.pitches.map((p) => p.pitchNumber).join() }}</div>
        <div v-else class="col">Niet toegewezen</div>
      </div>
      <div class="row">
        <div class="col">{{ $t("booking.registrationPlate") }}</div>
        <div v-if="booking.registrationPlate" class="col">{{ booking.registrationPlate }}</div>
        <div v-else class="col">Niet bekend</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FullCalendar from "@fullcalendar/vue";
import timeline from "@fullcalendar/timeline";
import resourceTimeline from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";

import * as grouper from "@/util/grouper";
import { DateHelpers } from "@/helpers";
import { RRule } from "rrule";

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const PitchRepository = RepositoryFactory.get("PitchRepository");
const BookingRepository = RepositoryFactory.get("BookingRepository");
import BookingStatus from "@/components/common/Status/BookingStatus";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import config from "@/config";

export default {
  components: {
    FullCalendar,
    BookingStatus,
    LoadingSpinner
  },

  data() {
    return {
      loading: false,
      scrolled: false,
      config: config,
      calendarApi: null,
      startDate: null,
      endDate: null,
      bookingChanges: {},
      calendarOptions: {
        plugins: [timeline, resourceTimeline, interactionPlugin, momentPlugin],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        initialView: "NccTimeLine",
        eventResize: this.eventResized,
        eventClick: this.eventClicked,
        eventDrop: this.eventDropped,
        eventContent: this.eventContent,
        stickyHeaderDates: true,
        headerToolbar: false,
        editable: true,
        weekends: true,
        events: [],
        resources: [],
        resourceAreaColumns: [
          {
            field: "title",
            headerContent: "Plaatsen"
          }
        ],
        views: {
          NccTimeLine: {
            type: "resourceTimeline",
            duration: { days: 29 },
            slotDuration: { hours: 24 }
          }
        },
        resourceAreaHeaderContent: null,
        resourceAreaWidth: "240px",
        resourceOrder: "sort",
        resourcesInitiallyExpanded: false,
        slotDuration: { hours: 24 },
        slotLabelFormat: [{ day: "numeric" }],
        slotLabelInterval: { hours: 24 },
        slotMinWidth: 5,
        height: "auto",
        locale: "nl"
      },
      viewMonth: this.$moment().startOf("month").toDate(),
      booking: {
        startDate: Date.UTC(-271821, 3, 20),
        endDate: Date.UTC(271821, 3, 20)
      }
    };
  },

  computed: {
    ...mapState("Campsites", ["currentCampsite"])
  },

  mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
    this.getPitches();
    this.resetViewPeriod();
    this.resetBookingChanges();
  },

  methods: {
    ...mapActions("CurrentBooking", ["fetchBooking"]),
    resetBookingChanges() {
      this.bookingChanges = {
        oldEvent: null,
        newEvent: null,
        oldResource: null,
        newResource: null,
        startDelta: 0,
        endDelta: 0,
        bookingDelta: 0,
        revert: null
      };
    },
    getPitches() {
      PitchRepository.getPitches(this.currentCampsite.id, 0, 999)
        .then((response) => {
          var assignedPitches = response.data.filter((p) => p.type === config.pitchTypes.CampingPitch && !p.blocked);
          var blockedPitches = response.data.filter((p) => p.type === config.pitchTypes.CampingPitch && p.blocked);

          this.calendarOptions.resources = [...grouper.groupParentsChildren(assignedPitches), ...grouper.groupParentsChildren(blockedPitches)].concat([
            {
              type: "Totals",
              title: "Beschikbaar",
              id: "-1", //so that the CSS knows where to insert the counts
              sort: -2,
              eventAllow: () => false
            },
            {
              type: "Unassigned",
              title: `Niet toegewezen (${grouper.countItemsByResourceId(this.calendarOptions.events, 0)})`,
              sort: -1,
              eventAllow: () => false,
              children: [{ id: 0, title: "" }]
            }
          ]);
        })
        .catch((error) => {
          this.$notify({
            group: "app",
            type: "error",
            title: this.$i18n.t("general_error_title"),
            text: error.response.data.message
          });
        });
    },
    getBookings() {
      this.loading = true;
      this.calendarApi.gotoDate(this.startDate.toDate());
      this.calendarOptions.events = [];
      var diff = this.endDate.diff(this.startDate, "days");
      this.calendarOptions.views.NccTimeLine.duration = { days: diff + 1 };

      PitchRepository.getBookingsWithPitch(this.currentCampsite.id, config.pitchTypes.CampingPitch, this.startDate.format("Y-MM-DD"), this.endDate.format("Y-MM-DD"), 0, 1000)
        .then((response) => {
          const bookingStates = Object.fromEntries(Object.entries(config.bookingStates).map((a) => a.reverse()));

          var relevantBookings = response.data.filter((item) => item.bookingStatus !== config.bookingStates.canceled && item.bookingType !== config.bookingTypes.longStorage);

          var withoutPitches = relevantBookings
            .filter((x) => x.pitches == null || x.pitches.length < 1)
            .map((b) => ({
              id: b.id,
              allDay: false,
              overlap: true,
              editable: this.eventEditable(b),
              durationEditable: this.eventStartEditable(b) || this.eventEndEditable(b),
              resourceEditable: this.eventResourceEditable(b),
              resourceId: b.pitchId || 0,
              start: this.$moment(b.startDate).hour(12).toDate(),
              end: this.$moment(b.endDate).hour(12).toDate(),
              title: b.name,
              classNames: [bookingStates[b.bookingStatus], `pay-${b.paymentStatus.toString().toLowerCase()}`],
              booking: b
            }));

          var withPitches = relevantBookings
            .filter((x) => x.pitches && x.pitches.length > 0)
            .flatMap((b) =>
              b.pitches.map((pitch) => ({
                id: b.id,
                allDay: false,
                overlap: !pitch.pitchId,
                editable: this.eventEditable(b),
                durationEditable: this.eventStartEditable(b) || this.eventEndEditable(b),
                resourceEditable: this.eventResourceEditable(b),
                resourceId: pitch.pitchId || 0,
                start: this.$moment(pitch.startDate).hour(12).toDate(),
                end: this.$moment(pitch.endDate).hour(12).toDate(),
                title: b.name,
                classNames: [bookingStates[b.bookingStatus], `pay-${b.paymentStatus.toString().toLowerCase()}`],
                booking: b,
                pitch: pitch
              }))
            );

          var bookingEvents = [...withPitches, ...withoutPitches];

          var daysOccupiedAvailability = grouper
            .calculateTotals(
              this.currentCampsite.campingPitchCapacity,
              bookingEvents.filter((item) => item.booking.bookingType !== config.bookingTypes.longStorage)
            )
            .filter((a) => a.start >= this.startDate.toDate());

          var daysEmptyAvailability = new RRule({
            freq: RRule.DAILY,
            interval: 1,
            dtstart: this.startDate.toDate(),
            until: this.endDate.toDate()
          })
            .all() // All dates for current range filtered out when day is same
            .filter((day) => (this.$moment(day).isSame(daysOccupiedAvailability.find((a) => this.$moment(a.start).isSame(day, "day"))?.start, "day") ? false : day))
            .map((d) => ({
              date: d,
              allDay: true,
              overlap: false,
              resourceId: -1,
              display: "background",
              classNames: "availability-success",
              title: this.currentCampsite.campingPitchCapacity
            }));

          this.calendarOptions.resources.filter((item) => item.sort === -1).forEach((r) => (r.title = `Niet toegewezen (${grouper.countItemsByResourceId(bookingEvents, 0)})`));

          //Assign both into the one-dimensional event array
          this.calendarOptions.events = bookingEvents.concat([...daysOccupiedAvailability, ...daysEmptyAvailability]);
        })
        .catch((error) => {
          if (error != null) {
            this.$notify({
              group: "app",
              type: "error",
              title: this.$i18n.t("general_error_title"),
              text: error.response != null && error.response.data != null ? error.response.data.message : error
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    eventEditable(booking) {
      return this.eventStartEditable(booking) || this.eventEndEditable(booking) || this.eventResourceEditable(booking);
    },
    eventStartEditable(booking) {
      return booking.bookingStatus !== config.bookingStates.canceled && booking.checkInDate === null;
    },
    pitchStartEditable(bookingChanges) {
      if (
        !this.$moment(bookingChanges.oldEvent.start).isSame(bookingChanges.oldEvent.extendedProps.booking.startDate, "day") && //Its okay if the startdate is not the same because then its not the first pitch
        !this.$moment(bookingChanges.newEvent.start).isBefore(bookingChanges.oldEvent.extendedProps.booking.startDate, "day") // and if its not before the whole booking
      ) {
        return true;
      }
      //Else fall back to normal behaviour

      return !(bookingChanges.bookingDelta !== 0 || bookingChanges.startDelta !== 0) && !this.eventStartEditable(bookingChanges.oldEvent.extendedProps.booking);
    },
    eventEndEditable(booking) {
      return booking.bookingStatus !== config.bookingStates.canceled && booking.checkOutDate === null;
    },
    eventResourceEditable(booking) {
      return booking.bookingStatus !== config.bookingStates.canceled && booking.checkOutDate === null;
    },
    eventResized(info) {
      if (this.preventWrongChangeoverTime(info)) {
        return;
      }

      this.bookingChanges.startDelta = info.startDelta.days;
      this.bookingChanges.endDelta = info.endDelta.days;
      this.bookingChanges.oldEvent = info.prevEvent || info.oldEvent;
      this.bookingChanges.newEvent = info.event;
      this.bookingChanges.revert = info.revert;
      this.$bvModal.show("booking-change-info");
    },
    async eventChangeConfirm() {
      if (this.bookingChanges.newEvent) {
        const booking = this.bookingChanges.newEvent.extendedProps.booking;
        const newStartDate = this.$moment(this.bookingChanges.newEvent.start);
        const newEndDate = this.$moment(this.bookingChanges.newEvent.end);
        const newResource = this.bookingChanges.newResource;

        if (this.bookingChanges.newResource || !this.$moment(booking.startDate).isSame(newStartDate) || !this.$moment(booking.endDate).isSame(newEndDate)) {
          var pitch = {
            pitchId: newResource ? parseInt(newResource.id) : booking.pitchId ? booking.pitchId : 0,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString()
          };

          const isPitchEvent = this.bookingChanges.newEvent.extendedProps.pitch != null;

          if (isPitchEvent) {
            pitch = this.bookingChanges.newEvent.extendedProps.pitch;
            pitch.startDate = newStartDate.toISOString();
            pitch.endDate = newEndDate.toISOString();
          }
          if (this.bookingChanges.newResource && this.bookingChanges.newResource.id) {
            pitch.pitchId = parseInt(this.bookingChanges.newResource.id);
            pitch.pitchNumber = this.bookingChanges.newResource.title;
          }

          var bookingsPitches = this.bookingChanges.newEvent.extendedProps.booking.pitches
            ? [pitch, ...this.bookingChanges.newEvent.extendedProps.booking.pitches?.filter((p) => p.id != pitch.id)]
            : pitch;

          let ranges = booking.pitches.map((p) => ({
            s: this.$moment(p.startDate),
            e: this.$moment(p.endDate)
          }));

          if (ranges && DateHelpers.getOverlapping(ranges)) {
            this.resetBookingChanges();
            this.$notify({
              group: "app",
              type: "error",
              title: this.$i18n.t("general_error_title"),
              text: this.$i18n.t("pitch.overlap_error")
            });
          } else {
            try {
              this.loading = true;

              const dateRange = {
                startDate: this.$moment(
                  Math.min.apply(
                    null,
                    bookingsPitches.map(function (e) {
                      return new Date(e.startDate);
                    })
                  )
                ).toISOString(),
                endDate: this.$moment(
                  Math.max.apply(
                    null,
                    bookingsPitches.map(function (e) {
                      return new Date(e.endDate);
                    })
                  )
                ).toISOString()
              };

              if (!this.$moment(booking.startDate).isSame(dateRange.startDate, "day") || !this.$moment(booking.endDate).isSame(dateRange.endDate, "day")) {
                await BookingRepository.updateDateRange(this.currentCampsite.id, booking.id, dateRange);
              }

              if (isPitchEvent || bookingsPitches.length > 0) {
                if (bookingsPitches.length > 1) {
                  await BookingRepository.assignPitches(this.currentCampsite.id, booking.id, bookingsPitches);
                } else {
                  if (pitch.pitchId > 0) {
                    await BookingRepository.assignPitch(this.currentCampsite.id, booking.id, pitch);
                  }
                }
              }
            } catch (error) {
              this.$notify({
                group: "app",
                type: "error",
                title: this.$i18n.t("general_error_title"),
                text: error.response != null && error.response.data != null ? error.response.data.message : error
              });
            } finally {
              this.resetBookingChanges();
              this.getBookings();
            }
          }
        }
      }
    },
    eventChangeCancel() {
      if (this.bookingChanges.oldEvent) {
        this.bookingChanges.revert();
        this.resetBookingChanges();
      }
    },
    eventContent(info) {
      let booking = info.event.extendedProps.booking;
      if (!booking) return info.event.title;

      let bookingTypeIconClass = "ncc-stalling";
      if (booking.bookingType === config.bookingTypes.shortStay && this.booking.memberNumber === null) {
        bookingTypeIconClass = "ncc-aspirant-lid";
      }
      if (booking.bookingType === config.bookingTypes.shortStay && this.booking.memberNumber !== null) {
        bookingTypeIconClass = "ncc-passantenplaats";
      }
      if (booking.bookingType === config.bookingTypes.longStay) {
        bookingTypeIconClass = "ncc-seizoensplaats";
      }

      return {
        html: `<i class="ncc-icon mr-1 ${bookingTypeIconClass}" aria-hidden="true"></i> ${booking.name}`
      };
    },
    eventClicked(info) {
      this.booking = info.event.extendedProps.booking;
      this.$bvModal.show("booking-info");
    },
    deltaDirectionString: function (days) {
      return days < 0 ? this.$t("booking.date_changes.earlier") : this.$t("booking.date_changes.later");
    },
    eventDropped(info) {
      if (this.preventWrongChangeoverTime(info)) {
        return;
      }

      this.bookingChanges.oldEvent = info.oldEvent;
      this.bookingChanges.newEvent = info.event;
      this.bookingChanges.oldResource = info.oldResource;
      this.bookingChanges.newResource = info.newResource;
      this.bookingChanges.bookingDelta = info.delta.days;
      this.bookingChanges.revert = info.revert;

      if (info.newResource && info.newResource.id === "0") {
        this.eventChangeCancel();
        return false;
      }
      this.$bvModal.show("booking-change-info");
    },
    preventWrongChangeoverTime(info) {
      if (parseInt(this.$moment(info.event.start).format("HH")) !== this.config.bookingChangeoverTime || parseInt(this.$moment(info.event.end).format("HH")) !== this.config.bookingChangeoverTime) {
        info.revert();

        return true;
      }

      return false;
    },
    openCalendar() {
      this.$refs.programaticOpen.showCalendar();
    },
    resetViewPeriod() {
      this.startDate = this.$moment.min(this.$moment().startOf("week"), this.$moment().subtract({ days: 3 }));
      this.endDate = this.$moment().endOf("week").add({ weeks: 3 });
      this.getBookings();
    },
    onPrev() {
      var diff = this.endDate.diff(this.startDate, "days");
      this.startDate.subtract({ days: diff + 1 });
      this.endDate.subtract({ days: diff + 1 });
      this.getBookings();
    },
    onNext() {
      var diff = this.endDate.diff(this.startDate, "days");
      this.startDate.add({ days: diff + 1 });
      this.endDate.add({ days: diff + 1 });
      this.getBookings();
    },
    onDateRangeUpdated(range) {
      if (range != null) {
        this.startDate = this.$moment(range.start).startOf("day");
        this.endDate = this.$moment(range.end).endOf("day");
        this.getBookings();
      }
    }
  }
};
</script>
